<template>
  <div style="display: flex;align-items:center;gap:0.1rem;">
    <!-- <span style="color: #FFF;" v-if="routeName.toLowerCase() == 'search'">Search</span> -->
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="35" viewBox="0 0 37 37">
      <g id="Group_2505" data-name="Group 2505" transform="translate(-271 -13)">
        <circle id="Ellipse_61" data-name="Ellipse 61" cx="19" cy="19" r="17.5" transform="translate(271 13)" fill="rgba(255,255,255,0.17)" :class="routeName.toLowerCase() == 'search' ? 'activesearch' : 'spacesearch'"  stroke="none" />
        <path id="Search" d="M12.792,9a6.767,6.767,0,1,0,3.883,12.317L20.5,25.142a1.475,1.475,0,0,0,1.593-.116c.639-.615.075-1.551.075-1.551L18.322,19.63A6.762,6.762,0,0,0,12.792,9Zm0,11.18A4.413,4.413,0,1,1,17.2,15.767a4.413,4.413,0,0,1-4.413,4.413Z" transform="translate(275.284 14.333)"  fill="#fff" class="fillblackwhite"  />
      </g>
    </svg>
  </div>
</template>
<script>

  export default{
    name :"SearchMobile",
    computed: {
      routeName() {
      let tempVar = this.$route.path;
      tempVar = tempVar.toLowerCase();
      if (tempVar.includes("movie")) {
        return "movies";
      }
      else if (tempVar.includes("channel")) {
        return "channels";
      } 
      else if (tempVar.includes("livetv")) {
        return "Live Tv";
      } 
      else if (tempVar.includes("tvshow")) {
          return "TV Shows";
        } 
       else if (tempVar.includes("shows") || tempVar.includes("/series")) {
        return "tv shows";
      } else if (tempVar.includes("songs") || tempVar.includes("music") || tempVar.includes('-video-songs')) {
        return "music";
      } else if (tempVar.includes("kids")) {
        return "kids";
      } else if (tempVar.includes("webseries")) {
        return "webseries";
      } else if (tempVar.includes("shorts")) {
        return "shorts";
      }  else if (tempVar.includes("watchlist")) {
        return "watchlist";
      } else if (tempVar.includes("devotional")) {
        return "devotional";
      } else if (tempVar.includes("viewAllPlans")) {
        return "plan";
      } else if (tempVar.includes("search")) {
        //search home
        return "search";
      } else if (tempVar.includes("epg")) {
        //search home
        return "epg";
      } else {
        return "home";
      }
    },
    }
  }
</script>
<style lang="scss" scoped>
  @import "@/sass/_variables.scss";
  @import "@/sass/_components.scss";

  .smallfill{
    fill:$clr-smallsearchfill
  }
  .spacesearch{
    fill:$continueWatch-nxt-slide-btn
  }
  .activesearch{
    fill:rgba($color: #f40707e6, $alpha: 1.0);
    transition: fill 0.2s ease;
  }
  .fillblackwhite{
    fill:$clr-saina-topnav-btn
  }
  .slow-underline::after{
    content: '';
    position: absolute;
    bottom: -3px;             
    background-color: #FF0000;            
    border-radius: 50%;             
    transition: width 0.6s ease;
    width: 100%;
    height: 3px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 0 0.2rem;
  }
</style>
    